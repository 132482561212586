@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $red;
    background-image: url('../img/bg.png');
    background-size: cover;
    background-position: center;
    color: $primary-color;
    font-family: $primary-font;
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a, button{
    color: inherit;
    transition: .3s all;
    text-decoration: none;
    @include hover{
        color: $primary-color-d;
    }
}

.container{
    max-width: rem(1180);
}

.dog{
    display: block;
    width: rem(58);
    margin: rem(20) auto;
}
#header{
    h1{
        img{
            display: block;
            margin: 0 auto rem(10);
            max-height: rem(32);
            
            @media screen and (max-width:1000px){
                max-height: rem(20);
            }
        }
    }
    @media screen and (min-width: 1001px){
        .dog{
            display: none;
        }
    }
}

#page-wrap{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

#main{
    text-align: center;

    .container{
        max-width: rem(1400);
    }

    .back-btn{
        display: inline-block;
        margin-top: rem(30);
        text-align: center;
        li, span{
            display: inline-block;
            font-size: rem(22);
        }
    }
    #contest-form{
        display: none;
        .contest-thanks{
            width: 100%;
        }
        p{
            width: 100%;
            font-size: rem(24);
        }
        .contest{
            width: rem(760);
            max-width: 100%;
            margin: 0 auto;
            .inputs-wrap{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            input{
                border: none;
                margin-bottom: rem(15);
                background: transparent;
                border-bottom: 2px solid $primary-color;
                color: $primary-color;
                font-size: rem(18);
                padding: rem(10);
                font-weight: bold;
                text-align: center;
                &::placeholder{
                    color: $primary-color;
                    font-weight: bold;
                    text-align: center;
                    text-transform: uppercase;
                }
                &:nth-child(1),&:nth-child(2),&:nth-child(4),&:nth-child(5){
                    flex-basis:  48%;
                    @media screen and (max-width: 640px){
                        flex-basis: 100%;
                    }
                }
                &:nth-child(3){
                    flex-basis:  100%;
                }
            }
            button{
                justify-self: center;
                color: $primary-color;
                background-color: transparent;
                display: block;
                width: 100%;
                max-width: rem(300);
                font-size: rem(18);
                display: inline-block;
                font-weight: bold;
                border: 2px solid $primary-color;
                padding: rem(8) rem(10);
                text-align: center;
                text-decoration: none;
                margin: rem(40) auto 0;
                @include hover{
                    background-color: $primary-color;
                    color: $red;
                }
            }
        }
    }
    #apple-form{
        display: none;
        p{
            font-size: rem(24);
        }
        input{
            border: none;
            background: transparent;
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            font-size: rem(18);
            padding: rem(3);
            &::placeholder{
                color: $primary-color;
                text-transform: uppercase;
            }
        }
        button{
            background: transparent;
            border: 1px solid $primary-color;
            color: $primary-color;
            border-radius: 0;
            font-size: rem(18);
            padding: rem(3) rem(5);
            @include hover{
                color: $red;
                background-color: $primary-color;
            }
        }
    }
    #start{
        .cta{
            font-size: rem(24);
            letter-spacing: .01em;
            line-height: 1.3;
            width: rem(850);
            max-width: 100%;
            margin: 1em auto;
            font-weight: bold;
            
            @media screen and (max-width:1000px){
                width: rem(500);
                font-size: rem(14);
            }
        }
        .btns{
            font-size: rem(17);
            display: flex;
            width: rem(880);
            max-width: 100%;
            margin: rem(20) auto;
            @media screen and (max-width:1000px){
                display: block;
                width: rem(350);
                a{
                    width: 100%;
                }
            }
            a{
                flex: 1;
                display: inline-block;
                font-weight: bold;
                border: 1px solid $primary-color;
                padding: rem(4) rem(10);
                text-align: center;
                text-decoration: none;
                &+a{
                    margin-left: rem(30);
                    @media screen and (max-width:1000px){
                        margin: rem(10) 0 0;
                    }
                }
                @include hover{
                    background-color: $primary-color;
                    color: $red;
                }
            }
        }
        .note{
            font-size: rem(10);
        }
        #prizes{
            margin: rem(30) 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-height:860px){
                margin-top: rem(60);
            }
            
            @media screen and (max-width:1000px){
                display: block;
                width: rem(340);
                max-width: 100%;
                margin: 0 auto;
            }
            .prize{
                border: 1px solid $primary-color;
                margin: rem(10); 
                padding: rem(20);
                width: rem(290);

                
                @media screen and (min-width:1200px){
                    width: rem(400)
                }
                
                @media screen and (max-width:1000px){
                    width: rem(340);
                    max-width: 100%;
                    margin: rem(10) 0;
                }
                p{
                    font-size: rem(12);
                    text-transform: none;
                    margin-bottom: 0;
                    &:first-child{
                        margin: 0 0 1em;
                        font-size: rem(19);
                        text-transform: uppercase;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
                &:nth-child(1){
                    order: 2;
                    width: rem(460);
                    @media screen and (min-width:1200px){
                        width: rem(530)
                    }
                }
                &:nth-child(3){
                    order: 2;
                }
                &:nth-child(3),&:nth-child(2){
                    img{
                        max-height: rem(136);
                    }
                }
            }
        }
    }
}

#footer{
    .container{
        display:flex;
        justify-content: space-between;
        align-items: center;
        
        @media screen and (max-width:1000px){
            flex-direction: column;
        }
        @media screen and (min-width:1001px){
            .social, .newsletter{
                flex-basis: rem(200);
            }
            .newsletter{
                order: 3;
            }
            .copyright{
                flex-basis: rem(400);
            }
        }
    }

    .social{
        padding: 0;
        margin: 0;
        font-size: rem(18);
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
    }

    .newsletter{
        text-align: right;
        
        @media screen and (max-width:1000px){
            margin: rem(8) 0 0;
        }
        input{
            border: none;
            color: $primary-color;
            background: transparent;
            border-bottom: 1px solid $primary-color;
            font-size: rem(12);
            padding: rem(3);
            &::placeholder{
                color: $primary-color;
                text-transform: uppercase;
            }
        }
        button{
            background: transparent;
            border: 1px solid $primary-color;
            color: $primary-color;
            border-radius: 0;
            font-size: rem(12);
            padding: rem(3) rem(5);
            @include hover{
                color: $red;
                background-color: $primary-color;
            }
        }
    }

    .copyright{
        font-size: rem(10);
        text-transform: uppercase;
        text-align: center;
    }
}