//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$red: #702023;
$primary-color: #C3A74F;
$primary-color-d: #A3872F;
$gray: #656565;

//Fonts
$primary-font: 'Crimson Pro', sans-serif;
